import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RankingPlayer } from "../../models/RankingPlayer";

export const RankingComponent: React.FunctionComponent = () => {

    const [rankingPlayers] = React.useState<RankingPlayer[]>([
        { Id: 1, Name: "Jin'Rokh Zenobi ", Points: 1191  },
        { Id: 2, Name: "K0rasu", Points: 1033 },
        { Id: 3, Name: "Regnatrix", Points: 1003 },
        { Id: 4, Name: "Thomas", Points: 353 },
        { Id: 5, Name: "Fianna", Points: 343 },
        { Id: 6, Name: "Bienchen", Points: 45 },
        { Id: 7, Name: "Nadin", Points: 0 }
    ]);

    const getSortedRankingPlayers = (): RankingPlayer[] => {
        return rankingPlayers.sort((a,b) => b.Points - a.Points);
    };

    return (<div>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Rang</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Punkte</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getSortedRankingPlayers().map((rankingPlayer: RankingPlayer, index: number) => (
                        <TableRow key={rankingPlayer.Id}>
                            <TableCell>{(index+1)}</TableCell>
                            <TableCell>{rankingPlayer.Name}</TableCell>
                            <TableCell>{rankingPlayer.Points}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>);
};