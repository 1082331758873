import ReactDOM from 'react-dom/client';
import { AppComponent } from './AppComponent';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { CssBaseline, ThemeOptions } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        }
    }
};
 
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<BrowserRouter>
    <ThemeProvider theme={themeOptions}>
        <CssBaseline/>
        <AppComponent/>
    </ThemeProvider>
</BrowserRouter>);