import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';

export const HeaderComponent: React.FunctionComponent = () => {
    
    const navigate = useNavigate();

    return (<div>
        <AppBar position="static" color={"primary"} style={{ alignItems: "center" }}>
            <Toolbar >
                <Button color="inherit" onClick={() => navigate("/")}>Ranking</Button>
            </Toolbar>
        </AppBar>
    </div>);
};