import React from 'react';
import { FooterComponent } from './components/footerComponent/FooterComponent';
import { HeaderComponent } from './components/headerComponent/HeaderComponent';
import { Route, Routes } from 'react-router';
import { RankingComponent } from './components/rankingComponent/RankingComponent';
import { Container } from '@mui/material';

export const AppComponent: React.FunctionComponent = () => {
	return (<Container maxWidth={false} disableGutters>
		<HeaderComponent/>
		<Routes>
			<Route path='/' element={<RankingComponent/>}/>
		</Routes>
		<FooterComponent/>
	</Container>);
};